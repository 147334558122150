<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Template Totem</strong>
        </CCardHeader>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard class="customCard">
          <CJumbotron>
            <div class="listTotem" v-for="item in itemsTotem" :key="item.id">
              <img
                :id="item.id"
                height="200"
                width="auto"
                :src="item.src"
                @click="setChooseTotem(item)"
                :class="{
                  totem: 'totem',
                  isActive: item.isActive,
                }"
              />
            </div>
          </CJumbotron>
        </CCard>
      </CCol>
    </CRow>

    <!-- Manifesti visibili -->
    <CRow v-if="itemsRepetitions && itemsRepetitions.length > 0">
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Manifesti totali visibili</strong>
          </CCardHeader>
          <CJumbotron>
            <div class="listTotem" v-for="item in itemsRepetitions" :key="item.id">
              <CButton
                v-if="repetitions == item"
                class="pressed-reps"
                :id="item.id"
                shape="pill"
                size="lg"
                v-on:click="chooseRepetition(item)"
                color="info"
              >
                {{ item }}
              </CButton>

              <CButton
                v-else
                class="not-pressed"
                :id="item.id"
                shape="pill"
                size="lg"
                v-on:click="chooseRepetition(item)"
                color="info"
              >
                {{ item }}
              </CButton>
            </div>

            <!--            <CSelect-->
            <!--                class="mr-2"-->
            <!--                :value.sync="repetitions"-->
            <!--                :options="itemsRepetitions"-->
            <!--                label="Ripetizioni"-->
            <!--            />-->
          </CJumbotron>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fine Manifesti visibili -->

    <!-- Impostazione colori totem -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Colori Totem</strong>
          </CCardHeader>

          <CCardBody>
            <table class="table">
              <thead>
                <tr>
                  <th>Primo Colore</th>
                  <th>Secondo Colore</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <v-swatches
                      v-model="gradient_one"
                      popover-x="right"
                      show-fallback
                      fallback-input-type="color"
                    ></v-swatches>
                  </td>
                  <td>
                    <v-swatches
                      v-model="gradient_two"
                      popover-x="right"
                      show-fallback
                      fallback-input-type="color"
                    ></v-swatches>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fine impostazione colori totem -->

    <!-- Caricamento foto -->
    <CRow v-if="uploadPhotoShow">
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Caricamento Foto</strong>
          </CCardHeader>

          <vue-dropzone
            :options="dropzoneOptions"
            :useCustomSlot="true"
            ref="myVueDropzone"
            @vdropzone-success="filesAdded"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">Carica qui le tue foto</h3>
              <div class="subtitle">Trascina o clicca per caricarle</div>
            </div>
          </vue-dropzone>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fine Manifesti visibili -->

    <!-- Elenco foto -->
    <CRow v-if="showPhotoList">
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Elenco Foto</strong>
          </CCardHeader>
          <CCardBody>
            <!-- Mostro tutte le foto del gruppo di uploadcare -->
            <CCol class="custom-elenco-foto" md="4" v-for="(item, index) in imgList" :key="item.id">
              <CCard>
                <!-- Anteprima della immagine -->
                <!-- @click="showViewPhoto(item)" -->
                <CCardBody class="custom-body-totem">
                  <img
                    class="custom-img"
                    :src="item.src"
                    width="100%"
                    height="auto"
                    @click="editPhoto(item, index)"
                  />

                  <CButton
                    shape="square"
                    variant="ghost"
                    class="custom-button-delete"
                    v-on:click="editPhoto(item, index)"
                    type="submit"
                    size="lg"
                    color="warning"
                  >
                    <CIcon name="cil-pencil" />
                    Modifica
                  </CButton>

                  <!-- Cancellazione della immagine -->
                  <CButton
                    shape="square"
                    variant="ghost"
                    class="custom-button-delete"
                    @click="deletePhoto(index)"
                    type="submit"
                    size="lg"
                    color="danger"
                  >
                    <CIcon name="cil-x-circle" />
                    Elimina
                  </CButton>
                </CCardBody>
              </CCard>
            </CCol>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fine Manifesti visibili -->

    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Info Manifesto</strong>
        </CCardHeader>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="4">
                <CCardHeader>
                  <strong>Tempo caricamento</strong>
                </CCardHeader>
                <CCardBody>
                  <CInput v-model="timeout" label="Tempo rotazione manifesti (secondi)" />
                </CCardBody>
              </CCol>

              <CCol sm="4">
                <CCardHeader>
                  <strong>Nome</strong>
                </CCardHeader>
                <CCardBody>
                  <CInput v-model="nome_personalizzato" label="Nome Personalizzato" />
                </CCardBody>
              </CCol>

              <CCol sm="4">
                <CCardHeader>
                  <strong>Meteo</strong>
                </CCardHeader>

                <CCardBody>
                  <SingleComune
                    title="Localita"
                    v-bind:selected="comune_selected"
                    v-on:getComune="setComune"
                  />
                </CCardBody>
              </CCol>
            </CRow>
          </CCardBody>
          <br />
        </CCard>
      </CCol>
    </CRow>

    <!-- Tempo caricamento -->
    <!--
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <strong>Tempo caricamento</strong>
              </CCardHeader>
              <CCardBody>
                <CInput
                    v-model="timeout"
                    label="Tempo rotazione manifesti (secondi)"
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        -->
    <!-- Fine Manifesti visibili -->

    <!-- Nome personalizzato -->
    <!--
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <strong>Nome Personalizzato</strong>
              </CCardHeader>
              <CCardBody>
                <CInput
                    v-model="nome_personalizzato"
                    label="Nome Personalizzato"
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        -->
    <!-- Fine Nome personalizzato -->

    <!-- Indirizzo RSS -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Indirizzo web RSS</strong>
          </CCardHeader>
          <CCardBody>
            <CInput v-model="url_rss" label="Url notizie rss" />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fine Indirizzo RSS -->

    <!-- Link video -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Video</strong>
          </CCardHeader>
          <CCardBody>
            <CInput v-model="link_video" label="Link video" />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- Fine  Link video -->

    <CRow>
      <CCol sm="12">
        <CCardBody>
          <CModal
            title="Gestione Foto"
            color="white"
            size="xl"
            fade
            :closeOnBackdrop="false"
            centered
            :show.sync="uploadPhotoModal"
          >
            <div class="upload-example">
              <cropper
                class="upload-example-cropper"
                :src="image"
                ref="cropper"
                :stencil-props="{
                  aspectRatio: this.aspectRatioVariable,
                }"
              />
            </div>

            <CButton
              class="custom-botton-save-and-end-edit-modal"
              v-on:click="crop"
              type="submit"
              shape="square"
              size="lg"
              color="info"
            >
              Clicca per ritagliare e salvare
            </CButton>
          </CModal>

          <!-- Modale che mostra l'anteprima dell'immagine -->
          <CModal
            class="modal-show-image"
            color="white"
            size="xl"
            fade
            :closeOnBackdrop="false"
            centered
            :show.sync="viewPhotoModal"
          >
            <img :src="image" width="100%" height="auto" />
          </CModal>
          <!-- Fine modale che mostra anteprima dell'immagine -->
        </CCardBody>
      </CCol>
    </CRow>

    <!--Salvataggio-->
    <CRow>
      <CCol sm="12">
        <CButton v-on:click="update" type="submit" size="lg" color="info">
          <CIcon name="cil-save" />
          Salva
        </CButton>
        <br />
        <br />
        <div v-if="updated_gestionetotem" class="alert alert-success" role="alert">
          Complimenti! Modifica avvenuta con successo!
        </div>

        <div v-if="errors_gestionetotem" class="alert alert-warning" role="alert">
          Attenzione! Modifica non applicata!
        </div>
      </CCol>
    </CRow>
    <!-- Fine Manifesti visibili -->
  </div>
</template>

<script>
//https://github.com/uploadcare/uploadcare-upload-client
//https://rowanwins.github.io/vue-dropzone/docs/dist/index.html#/installation
import SingleComune from '@/views/funer24/components/SingleComune';
import UploadClient from '@uploadcare/upload-client';
import { Cropper, Preview } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import VueUploadcare from 'vue-uploadcare';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  components: {
    VSwatches,
    VueUploadcare,
    SingleComune,
    Cropper,
    Preview,
    vueDropzone: vue2Dropzone,
  },
  data: function () {
    return {
      data: [],
      options: [],
      itemsTotem: [],
      imgList: [],
      uploadPhotoModal: false,
      showPhotoModal: false,
      viewPhotoModal: false,
      clientUploadCare: new UploadClient({ publicKey: '83237effd4da9f06ef10' }),
      uploadCarePublicKey: '83237effd4da9f06ef10',
      url_rss: null,
      link_video: null,
      format_repetitions: [1, 2, 3, 4, 6, 8],
      format_type: null,
      format_view: null,

      format_view_selected: null,
      repetitions: null,

      itemsRepetitions: [],

      format_m1: [1, 2, 3, 4, 5, 6],
      format_m2: [2, 4, 6, 8, 10, 12],
      format_m3: [3, 6, 9, 12, 15, 18],
      format_m4: [4, 8, 12, 16, 20, 24],
      format_m6: [6, 12, 18, 24, 30, 36],
      format_m8: [8, 16, 24, 32, 40, 48],

      format_m1_selected: null,
      format_m2_selected: null,
      format_m3_selected: null,
      format_m4_selected: null,
      format_m6_selected: null,
      format_m8_selected: null,

      result: {
        coordinates: null,
        image: null,
      },

      dropzoneOptions: {
        url: 'https://api.funer24.com/post',
        thumbnailWidth: 200,
        maxFilesize: 50000000000,
      },

      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      selected_images: null,
      current_images: null,
      image: null,
      img_azienda: '',
      current_index_images: null,
      nome_personalizzato: null,

      timeout: 0,
      gradient_one: null,
      gradient_two: null,
      comuni: null,
      comune_selected: null,
      username: null,
      password: null,
      email: null,
      aspectRatioVariable: null,
      updated_gestionetotem: null,
      errors_gestionetotem: null,
      uploadPhotoShow: false,
      showPhotoList: false,
    };
  },
  methods: {
    setComune(data) {
      this.comuni = data;
    },

    createTotemList(id) {
      this.itemsTotem = [];
      this.repetitions = null;
      this.itemsRepetitions = null;

      for (const value of this.format_view) {
        if (id === value.id) {
          this.format_view_selected = value.manifesti;
          this.format_type = value;
          this.uploadPhotoShow = value.uploadPhotoShow;
          this.aspectRatioVariable = value.aspectRatioVariable;
          this.showPhotoList = value.showPhotoList;

          this.itemsTotem.push({
            id: value.id,
            src: value.template,
            isActive: true,
          });
        } else {
          this.itemsTotem.push({
            id: value.id,
            src: value.template,
            isActive: false,
          });
        }
      }

      this.repetitions = this['format_m' + this.format_view_selected + '_selected'];
      this.itemsRepetitions = this['format_m' + this.format_view_selected];
    },

    setChooseTotem(item) {
      this.createTotemList(item.id);
    },

    chooseRepetition(item) {
      this.repetitions = item;
    },

    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.image = canvas.toDataURL('image/jpeg', 0.7);
      this.photoUploader();
      this.$delete(this.imgList, this.current_index_images);
      this.showPhotoModal = false;
      this.uploadPhotoModal = false;
    },

    fillImgList(obj) {
      const baseLink = 'https://ucarecdn.com/';
      this.imgList.push({ uuid: obj.uuid, id: obj.file_id, src: baseLink + obj.uuid + '/' });
    },

    getListImage(uuid) {
      axios
        .get(
          'https://upload.uploadcare.com/group/info/?pub_key=' +
            this.uploadCarePublicKey +
            '&group_id=' +
            uuid
        )
        .then(
          (response) => {
            if (response.status === 200) {
              this.imgList = [];
              this.img_azienda = uuid;
              for (const obj of response.data.files) {
                this.fillImgList(obj);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },

    filesAdded(file, response) {
      const fileToUpload = this.dataURLtoFile(file.dataURL, 'image');

      Promise.resolve(
        this.clientUploadCare
          .uploadFile(fileToUpload)
          .then((obj) => {
            return obj;
          })
          .then((obj) => {
            this.fillImgList(obj);
            this.groupUploader();
          })
      );
      this.$refs.myVueDropzone.removeFile(file);
    },

    fillImgUrl(groupCdnUrl) {
      this.img_azienda = groupCdnUrl;
    },

    getData() {
      // Lista dei template da caricare
      axios.get('/dashboard/api/v0/totem/layout/list').then(
        (response) => {
          if (response.status === 200) {
            this.format_view = response.data.results;

            // Api per ottenere i dati di un totem
            axios.get('/dashboard/api/v0/totem/get/' + this.$route.params.idTotem).then(
              (response) => {
                if (response.status === 200) {
                  this.data = response.data;
                  this.id = this.data.id;
                  this.username = this.data.username;
                  this.password = this.data.password;
                  this.email = this.data.email;

                  this.gradient_one = this.data.gradient_one;
                  this.gradient_two = this.data.gradient_two;

                  this.timeout = this.data.timeout;
                  this.comune_selected = this.data.comuni;
                  this.url_rss = this.data.url_rss;
                  this.link_video = this.data.link_video;
                  this.nome_personalizzato = this.data.nome_personalizzato;

                  // Engine format view
                  this.format_type = this.data.format_type;
                  this.format_m1_selected = this.data.format_m1;
                  this.format_m2_selected = this.data.format_m2;
                  this.format_m3_selected = this.data.format_m3;
                  this.format_m4_selected = this.data.format_m4;
                  this.format_m6_selected = this.data.format_m6;
                  this.format_m8_selected = this.data.format_m8;

                  if (this.data.img_azienda !== undefined && this.data.img_azienda.length > 0) {
                    this.img_azienda = this.data.img_azienda
                      .replace('https://ucarecdn.com/', '')
                      .replace('/', '');
                    this.getListImage(this.img_azienda);
                  }

                  this.createTotemList(this.format_type.id);
                }
              },
              (error) => {
                console.log(error);
              }
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    /** Uploda tutti gli uuid che si trovano nell'array imgList
     */
    groupUploader() {
      if (this.imgList.length > 0) {
        let listUuid = [];
        for (const obj of this.imgList) listUuid.push(obj.uuid);

        return Promise.resolve(
          this.clientUploadCare.uploadFileGroup(listUuid).then((obj) => {
            this.fillImgUrl(obj.cdnUrl);
            return obj;
          })
        );
      }
    },

    // https://uploadcare.com/docs/uploads/file-uploader/
    photoUploader() {
      const fileToUpload = this.dataURLtoFile(this.image, 'image');
      Promise.resolve(
        this.clientUploadCare
          .uploadFile(fileToUpload)
          .then((obj) => {
            return obj;
          })
          .then((obj) => {
            this.fillImgList(obj);
            this.groupUploader();
            return obj;
          })
      );
    },

    /**
     * Cancella la foto solo logicamente, necessita la rigenerazione del gruppo senza la foto eliminata
     * con le foto meno quella rimossa
     * @param index
     */
    deletePhoto(index) {
      this.$delete(this.imgList, index);
      this.groupUploader();
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    editPhoto(item, index) {
      this.image = item.src;
      this.current_images = item.src;
      this.uploadPhotoModal = true;
      this.current_index_images = index;
    },

    showViewPhoto(item) {
      this.image = item.src;
      this.current_images = item.src;
      this.viewPhotoModal = true;
    },

    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      fetch(
        process.env.VUE_APP_BASEURL +
          `/settings/api/v0/lista-comuni?comune__icontains=${escape(search)}`
      ).then((res) => {
        res.json().then((json) => (vm.options = json.results));
        loading(false);
      });
    }, 100),

    patch() {
      axios
        .patch('/dashboard/api/v0/totem/update/' + this.id, {
          timeout: this.timeout,
          comuni: this.comune_selected.id,
          url_rss: this.url_rss,
          link_video: this.link_video,
          nome_personalizzato: this.nome_personalizzato,

          // Forma engine
          format_type: this.format_type,
          format_m1: this.format_m1_selected,
          format_m2: this.format_m2_selected,
          format_m3: this.format_m3_selected,
          format_m4: this.format_m4_selected,
          format_m6: this.format_m6_selected,
          format_m8: this.format_m8_selected,
          // end-format-engine

          gradient_one: this.gradient_one,
          gradient_two: this.gradient_two,
          img_azienda: this.img_azienda,
        })
        .then(
          (response) => {
            if (response.status === 200) {
              this.updated_gestionetotem = true;
              this.$router.push({
                name: 'Lista',
              });
            }
          },
          (error) => {
            console.log(error);
            this.errors_gestionetotem = true;
            setTimeout(() => (this.errors_gestionetotem = false), 3000);
          }
        );
    },

    update() {
      // Type of format repetitions => this.format_type.manifesti
      // Repetitions Number => this.repetitions

      for (const format of this.format_repetitions) {
        if (this.format_type.manifesti === format) {
          this['format_m' + format + '_selected'] = this.repetitions;
        } else {
          this['format_m' + format + '_selected'] = null;
        }
      }

      this.patch();
    },
  },
  mounted() {
    this.getData();
  },
  name: 'GestioneTotem',
};
</script>

<style>
span.d-block.custom-center-totem {
  margin: 0 auto;
}

img.totem {
  opacity: 0.3;
}

img.isActive.totem {
  opacity: revert;
}

.listTotem {
  display: inline-flex;
  margin: 1%;
}

.jumbotron {
  background-color: #ffffffdb;
  padding-top: 16px;
  padding-bottom: 16px;
}

img.totem {
  border: 8px solid #cecece;
  border-radius: 8px;
}

.card {
  background-color: #ffffffdb;
}

.upload-example-cropper {
  border: solid 1px #eee;
  height: 600px;
  width: auto;
  z-index: 99999999;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  background: #3fb37f;
  cursor: pointer;
  transition: background 0.5s;
}

.button:hover {
  background: #38d890;
}

.button input {
  display: none;
}

span.buttonSaveImage {
  margin-left: 2%;
}

button.btn.custom-button-modify.btn-white.btn-lg {
  width: 50%;
}

button.btn.custom-button-remove.btn-danger.btn-lg {
  width: 50%;
}

footer.card-footer.custom-footer-totem {
  padding: 0;
  margin: 0;
  border: 0 !important;
}

.card-body.custom-body-totem {
  margin: 0;
  padding: 0;
}

footer.modal-footer {
  display: none;
}

.modal-body {
  padding: 0;
}

header.modal-header {
  padding: 1%;
}

img.custom-img:hover {
  opacity: 0.5;
}

.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  background: #fff !important;
}

.dropzone-custom-content {
  padding: 80px;
}

h3.dropzone-custom-title {
  font-weight: 900;
  font-size: 3vw;
}

div.custom-elenco-foto.col-md-4 {
  display: inline-flex;
}

button.btn.custom-button-delete {
  width: 50%;
}

button.btn.custom-botton-save-and-end-edit-modal.btn-info.btn-lg.btn-pill {
  width: 100%;
  height: 75px;
  font-weight: 700;
}

button.btn.pressed-reps.btn-info.btn-lg.btn-pill {
  background-color: #f9b115;
}

.vue-simple-handler {
  background: #670196 !important;
  height: 14px;
  width: 14px;
}
</style>
